<template>
    <page-layout linkDefault="/" title="Discount Card" :backButton="true">
        <div class="w-full flex justify-center">
            <img :src="require('@/assets/Card.png')" alt="" class="w-9/10 -ml-4" />
        </div>
        <div class="flex flex-col items-start justify-start p-8 space-y-3 max-w-md mx-auto">
            <p class="font-poppins font-bold text-xl align-middle">You’re good to go!</p>
            <p class=" font-poppins font-normal text-sm text-left">Say hello to your new best friend - the RuuPlus discount card! You’ll be receiving a confirmation email shortly, 
                where pickup details will be conveyed. After that, you’ll be able to officially use our discounts at your favorite stores and enjoy the perks of being a student without 
                breaking the bank.</p>
            <p class=" font-poppins font-normal text-sm text-left"> We hope you find some of your favorites among our vendors and have a wonderful time!
                Make sure to bring your card everywhere with you to enjoy the full benefits of your RuuPlus card.</p>

            <Button size="normal" shape="full" color="primary" @click="this.$router.push('/')">Confirm</Button>
        </div>
    </page-layout>
</template>
 
<script>
import PageLayout from "../components/base/pageLayout.vue";
import Button from "../components/Feed/Button.vue"
export default {
    components: { PageLayout, Button },
}
</script>
 
<style lang="scss" scoped></style>